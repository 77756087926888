import { render, staticRenderFns } from "./Consultoria.vue?vue&type=template&id=50acbfa9&scoped=true&"
var script = {}
import style0 from "./Consultoria.vue?vue&type=style&index=0&id=50acbfa9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50acbfa9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VResponsive,VRow})
