<template>
  <v-container fluid class="pa-0 ma-0">
    <hero></hero>
    <criacao></criacao>
    <manutencao></manutencao>
    <atendimento></atendimento>
    <adaptacoes></adaptacoes>
    <consultoria></consultoria>
    <treinamento></treinamento>
  </v-container>
</template>

<script>
import Adaptacoes from '@/components/servicos/Adaptacoes.vue'
import Atendimento from '@/components/servicos/Atendimento.vue'
import Consultoria from '@/components/servicos/Consultoria.vue'
import Criacao from '@/components/servicos/Criacao.vue'
import Hero from '@/components/servicos/Hero.vue'
import Manutencao from '@/components/servicos/Manutencao.vue'
import Treinamento from '@/components/servicos/Treinamento.vue'

export default {
  components: {
    Adaptacoes,
    Atendimento,
    Consultoria,
    Criacao,
    Hero,
    Manutencao,
    Treinamento,
  },
}
</script>
